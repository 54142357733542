import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import ClinicFinder from "./ClinicFinder"
import './Ankle.scss'

export default function Ankle() {
  const data = useStaticQuery( graphql`
    query {
      allMdx(filter: { fileAbsolutePath: { regex: "//ankle//" } }){
        nodes {
          slug
          body
        }
      }
    }
  `)
  const clinicTour = data.allMdx.nodes.filter(node => node.slug === "ankle/clinic-tour")[0]
  return (
    <div className='Ankle'>
      <div className="section clinic-tour">
        <div className="person">
          <img src="/assets/ankle_provider.png" alt="provider"/>
        </div>
        <div className="text">
          <MDXProvider components={{}}>
            <MDXRenderer>
              {clinicTour.body}
            </MDXRenderer>
          </MDXProvider>
          <button>START YOUR TOUR</button>
        </div>
      </div>
      <div className="section find-clinic">
         <ClinicFinder />
        <div className="person">
        <img src="/assets/ankle_patient.png" alt="patient"/>
        </div>
      </div>
    </div>
  )
}