import { Link } from 'gatsby'
import React from 'react'
import './Footer.scss'

const Footer = ({children}) => {
  const linkList = [
    { text: "Getting to Know Your Body", to: "/getting-to-know-your-body"},
    { text: "Sex and Relationships", to: "/sex-and-relationships"},
    { text: "Healthy Lifestyles", to: "/healthy-lifestyles" },
    { text: "Birth Control", to: "/birth-control"},
    { text: "Transitioning to Adult Care", to: "/transitioning-to-adult-care" },
    { text: "Preparing for Your Health Care Visit", to: "/preparing-for-your-health-care-visit"},
    { text: "Appointment 101", to: "/appointment-101"},
    { text: "How to Talk to Your Provider", to: "/how-to-talk-to-your-provider"},
    { text: "Resources", to: "/resources" },
    { text: "About Us", to: "/about-us"},
  ]

  const links = linkList.map( link => {
    return <Link className="link" key={link.to} to={link.to}>{link.text}</Link>
  })

  return (
    <div className='Footer'>
      <div className="logos">
        <img src="/assets/logo_big.svg" alt="WINK Logo"/>
        <div className="bottom-logos">
          <img src="/assets/UCSF_logo.svg" alt="UCSF logo"/>
          <img src="/assets/PCRHP_logo.svg" alt="PCHRP logo"/>
        </div>
      </div>
      <div className="links">
        {links}
      </div>
      <div className="notices">
        <p>© 2021 The University of California, San Francisco. <a href="/privacy-policy"> Privacy Policy </a></p>
        <p>
         This tool is provided for general informational purposes only and is not intended as, nor should it be considered a substitute for, professional medical advice. Do not use the information on this website for diagnosing or treating any medical or health condition. If you have or suspect you have a medical problem, promptly contact your professional health care provider.
        </p>
      </div>
    </div>
  )
}

export default Footer