import React from 'react'
import './Carousel.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination"
// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

const Carousel = ({title, children}) => {
  let slides;
  if (Array.isArray(children)) {
    slides = children.map((slide, index) => {
      return <SwiperSlide key={index}>{slide}</SwiperSlide>
    })
  }

  return (
    <div className='Carousel'>
      <div className='title'>
        <h2>{title}</h2>
      </div>
      <Swiper
        spaceBetween={30}
        slidesPerView={'auto'}
        centeredSlides={true}
        pagination={{
          "clickable": true}}
        className="mySwiper"
        //onSlideChange={() => console.log('slide change')}
        //onSwiper={(swiper) => console.log(swiper)}
      >
        {slides}
      </Swiper>
    </div>
  )
}

export default Carousel