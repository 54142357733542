import React from 'react'
import { Link } from 'gatsby'
import './BreadCrumbs.scss'

const BreadCrumbs = ({sectionTitle, topicTitle, detailTitle, path}) => {
  const sectionPath = '/' + path.split('/')[0]
  const topicPath = sectionPath + '/' + path.split('/')[1]
  const detailPath = topicPath + '/' + path.split('/')[2]
  const caret = () => <span> {'>'} </span>

  return (
    <div className='BreadCrumbs'>
      <Link to={'/'}>Home</Link>
      {caret()}
      <Link to={sectionPath}>{sectionTitle}</Link>
      {topicTitle && caret()}
      {topicTitle && <Link to={topicPath}>{topicTitle}</Link>}
      {detailTitle && caret()}
      {detailTitle && <Link to={detailPath}>{detailTitle}</Link>}
    </div>
  )
}

export default BreadCrumbs