import React from 'react'
import Markdown from 'markdown-to-jsx';
import './FactBox.scss'

const FactBox = ({children}) => {
  return (
    <div className='fact-box-container'>
      <Markdown>{children}</Markdown>
    </div>
  )
}

export default FactBox